<template>
    <section class="news">
        <tool-bar v-bind:lang.sync="lang">
            News
            <template slot="buttons">
                <div class="level-item">
                    <button class="button"
                            v-on:click="addNews">
                        <span class="icon is-small">
                            <font-awesome-icon icon="plus"></font-awesome-icon>
                        </span>
                        <span>Add News Article</span>
                    </button>
                </div>
                <div class="level-item">
                    <button class="button"
                            v-bind:class="{ 'is-loading': loading }"
                            v-bind:disabled="!edited"
                            v-on:click="save">
                        <span class="icon is-small">
                            <font-awesome-icon icon="save"></font-awesome-icon>
                        </span>
                        <span>Save</span>
                    </button>
                </div>
            </template>
        </tool-bar>

        <article class="message is-dark">
            <div class="message-body" style="background-color: rgba(255, 255, 255, 1)">
                You can drag the news articles below to reorder them. It will be reflected in the main website upon
                saving.
            </div>
        </article>

        <div class="container py-5">
            <draggable v-model="news"
                       v-on:end="onDragEnd">
                <transition-group>
                    <news-item v-for="article in news"
                               v-bind:key="article._id"
                               v-bind:lang="lang"
                               v-bind:article="article">
                    </news-item>
                </transition-group>
            </draggable>
        </div>
    </section>
</template>

<script>
import draggable from "vuedraggable"
import ToolBar from "../layouts/ToolBar"
import NewsItem from "@/components/page/News/NewsItem";

export default {
    name: "News",
    components: {
        NewsItem,
        draggable,
        ToolBar
    },
    data() {
        return {
            loading: false,
            edited: false,
            lang: "en",

            news: []
        }
    },
    computed: {
        newsInStore () {
            return this.$store.state.mall.news;
        },
    },
    methods: {
        onDragEnd() {
            this.edited = true
        },

        addNews() {
            this.$router.push("/page/news/new");
        },

        async save () {
            this.loading = true
            console.log(this.news);
            let result
            try {
                result = await this.$store
                    .dispatch(
                        "mall/updateMall",
                        {
                            "news": this.news.map(({ _id }) => _id)
                        }
                    );
            } catch (e) {
                console.log(e)
                this.loading = false
                return
            }
            this.loading = false
        },
    },
    mounted() {
        this.news = this.newsInStore;
    },
    watch: {
        "newsInStore" (newVal) {
            this.news = newVal;
        },
    }
}
</script>

<style lang="scss" scoped>
.message {
    margin-top: 25px;
    margin-left: 25px;
    margin-right: 25px;
}

.news {
    position: relative;
    background-color: rgba(245, 245, 245, 1);

    & > .tabs {
        margin-top: 1rem;
        margin-bottom: 1rem;

        & > ul {
            display: flex;
            justify-content: center;
        }
    }

    & > .container {
        background-color: rgba(255, 255, 255, 1);
    }
}
</style>
